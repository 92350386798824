module.exports = {
  title: 'Blog', // Required
  author: 'Kevin Turcios', // Required
  description: 'Software Engineer and Open Source Contributor',
  primaryColor: '#003366', // Required
  showHeaderImage: true,
  showShareButtons: true,
  postsPerPage: 5, // Required
  social: {
    website: 'https://turcios.dev',
    github: 'https://github.com/kturcios',
    twitter: 'https://twitter.com/kjturcios',
    linkedin: 'https://www.linkedin.com/in/kturcios/',
  },
  pathPrefix: '/blog',
  siteUrl: 'https://turcios.dev',
};
